import {EntityPicker } from "../../form/rsuite-form";
import React from "react";

export const TAX = Object.freeze([
    {value: .0, label: "0%"},
    {value: .05, label: "5%"},
    {value: .07, label: "7%"},
    {value: .16, label: "16%"},
    {value: .19, label: "19%"}
]);

export const AccountSelect = ({name, organization, value, ...props}) => (
    <EntityPicker
        name={name}
        modelId="accounting/ledger-account"
        conditions={{organization: {$in: [{id: organization, modelId: "organization/organization"}]}}}
        cleanable={false}
        valueMapper={({$order, label, value: {id}}) => ({label, value: {id, modelId: "accounting/ledger-account", currency: $order ?? "EUR"}})}
        value={value?.id ? {id: value.id, modelId: "accounting/ledger-account", currency: value.currency} : null}
        {...props}
    />
);

export const PeriodSelect = ({name, organization, ...props}) => (
    <EntityPicker
        name={name}
        modelId="accounting/period"
        conditions={{organization: {$in: [{id: organization, modelId: "organization/organization"}]}}}
        cleanable={false}
        {...props}
    />
);
