import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {Button, Drawer, FlexboxGrid, Nav, Placeholder} from "rsuite"
import EntryEditorSimple from "./editor-simple"
import EntryEditorComplex from "./editor-complex"
import useAccountingStyles from "../styles"
import {selectContext} from "../store/entries-slice"
import {
    closeEditor,
    selectBooking,
    selectCanSave,
    selectCanSimple,
    selectEditorDrawer,
    selectTab,
    setTab
} from "../store/editor-slice"
import SioIcon from "../../icon/rsuite-icon-font/SioIcon"
import {SaveButton} from "./save-button";

export default function EntryEditor() {
    const dispatch = useDispatch()
    const context = useSelector(selectContext)
    const {id} = useSelector(selectBooking)
    const [open, loading] = useSelector(selectEditorDrawer)
    const tab = useSelector(selectTab)
    const canSimple = useSelector(selectCanSimple)
    const canSave = useSelector(selectCanSave)
    const styles = useAccountingStyles()
    const disabled = loading || !canSave

    return (
        <Drawer className={styles.drawer} open={open} backdrop={false} onClose={() => dispatch(closeEditor())}
                placement="bottom" size={"complex" === tab ? "md" : "sm"}>
            <Drawer.Header closeButton={false}>
                <FlexboxGrid style={{flexGrow: 1}}>
                    <FlexboxGrid.Item style={{flexGrow: 1}}>
                        {!loading && (
                            <Nav appearance="tabs">
                                <Nav.Item active={"simple" === tab} disabled={!canSimple}
                                          onClick={() => dispatch(setTab("simple"))}>
                                    Einfach
                                </Nav.Item>

                                <Nav.Item active={"complex" === tab}
                                          onClick={() => dispatch(setTab("complex"))}>
                                    Split
                                </Nav.Item>
                            </Nav>
                        )}
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item style={{width: "auto"}} className="text-right">
                        {"stack" === context ? (
                            <SaveButton id={ id ? "Speichern" : 'submit-booking'} appearance="primary" disabled={disabled}>
                                {id ? "Speichern" : "Hinzufügen"}
                            </SaveButton>
                        ) : (
                            <SaveButton className={styles.danger} disabled={disabled}
                                        icon={<SioIcon icon="fa fa-lock"/>}>
                                Buchen
                            </SaveButton>
                        )}
                        <Button onClick={() => dispatch(closeEditor())}>Abbrechen</Button>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Drawer.Header>
            <Drawer.Body>
                {loading ? (
                    <Placeholder.Grid active={true}/>
                ) : (
                    "simple" === tab ? <EntryEditorSimple/> : <EntryEditorComplex/>
                )}
            </Drawer.Body>
        </Drawer>
    )
}

